import React, { Component } from 'react';
import { withRouter } from "react-router";
import { formatArtist } from '../services/Artist';

import { IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import Settings from '@mui/icons-material/Settings';

import AlertMessage from './AlertMessage';
import RadioCheck from './RadioCheck';

import '../css/Header.css';
import '../css/Game.css';

class Game extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            video: null,
            artists: [],
            state: 'idle',
            duration: 15,
            choice: null,
            choiceInput: '',
            answer: null,
            bar: 100,
            answersDisabled: false,
            barInterval: null,
            button: 'START GAME',
            popup: false,
            settings_tags: [],
            settings_score: 100,
            settings_pools: {},

            error: null,
            errorShown: false,
        };
    }

    componentDidMount() {
        document.title = 'Sakutrain - Game';
        if (this.props.hasAccount) {
            this.initGame();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.hasAccount !== prevProps.hasAccount) {
            if (this.props.hasAccount) {
                this.initGame();
            }
        }
    }
    componentWillUnmount() {
        this.props.socket.off('error');
        this.props.socket.off('name');
        this.props.socket.off('randomPost');
        this.props.socket.off('endRound');
        this.props.socket.off('endingRound');
        this.props.socket.off('endGame');
        this.props.socket.off('settings');
        this.props.socket.off('settingsSaved');
        this.props.socket.emit('leave');
        clearInterval(this.state.barInterval);
    }

    initGame() {
        const id = this.props.match.params.id;
        const _this = this;
        
        this.props.socket.emit('join', {
            name: this.props.name,
            id: id
        });

        this.props.socket.on('error', (error) => {
            this.setState({
                'error': error,
                'errorShown': true
            });
        });
        
        this.props.socket.on('name', (name) => {
            this.setState({
                'name': name
            });
        });
        this.props.socket.on('randomPost', (data) => {
            _this.newRound(data);
        });
        this.props.socket.on('endingRound', () => {
            console.log('ending round')
            this.setState({
                state: 'endingRound',
                answersDisabled: true,
                bar: 0,
            });
        });
        this.props.socket.on('endRound', (data) => {
            console.log('endround',data);
            _this.props.addPost(data.post);
            _this.stopRound(data);
        });
        this.props.socket.on('endGame', () => {
            _this.endGame();
        });
        this.props.socket.on('settings', (data) => {
            _this.setState({
                settings_tags: data.tags,
                settings_score: data.score,
                settings_presumed: data.presumed,
                settings_free_choice: data.freeChoice,
                settings_pools: data.pools
            });
            console.log("Settings",data);
        });
        this.props.socket.on('settingsSaved', (data) => {
            this.setState({
                'popup': false
            });
        });
    }

    saveSettings() {
        this.props.socket.emit('setSettings', {
            'tags': this.state.settings_tags,
            'score': this.state.settings_score,
            'presumed': this.state.settings_presumed,
            'free_choice': this.state.settings_free_choice,
        });
        this.setState({
            'popup': false
        });
    }
    changeTags(e) {
        this.setState({
            'settings_tags': e.target.value.split(',')
        });
    }
    changeScore(e) {
        this.setState({
            'settings_score': e.target.value
        });
    }
    changePresumed(val) {
        this.setState({
            'settings_presumed': val
        });
    }
    changeFreeChoice(val) {
        this.setState({
            'settings_free_choice': val
        });
    }
    changePool(pool) {
        this.setState({
            'settings_tags': this.state.settings_pools[pool]
        });
    }

    newRound(data) {
        this.setState({
            video: data.video,
            artists: data.artists,
            duration: data.duration,
            state: 'inRound',
            bar: 100,
            answersDisabled: false,
            choice: null,
            choiceInput: '',
            sentChoice: false,
            answer: null
        });

        let _this = this;
        let interval = setInterval(() => {
            _this.setState({
                'bar': Math.max(0, this.state.bar - (100 / this.state.duration)),
            });
            if (_this.state.bar === 0 || _this.state.state === 'endRound') {
                clearInterval(interval);
            }
        }, 1000);
        this.setState({
            'barInterval': interval
        });
    }

    stopRound(data) {
        this.setState({
            state: 'endRound',
            answer: data.answer,
            bar: 0,
            answersDisabled: true,
        });
    }

    startGame() {
        if (this.state.button === 'START GAME') {
            this.props.socket.emit('start');
            this.setState({
                'button': 'LOADING...'
            });
        }
    }
    endGame() {
        this.setState({
            video: null,
            artists: [],
            state: 'idle',
            choice: null,
            answer: null,
            bar: 0,
            answersDisabled: true,
            barInterval: null,
            button: 'START GAME'
        });
    }

    setChoiceInput(e) {
        if (this.state.state === 'inRound') {
            this.setState({
                'choiceInput': e.target.value,
                'choice': e.target.value,
            });
        }
    }
    sendChoiceInput() {
        if (this.state.state === 'inRound') {
            this.props.socket.emit('setChoice', this.state.choiceInput);
            this.setState({
                'sentChoice': true,
            });
        }
    }

    setChoice(choice) {
        if (this.state.state === 'inRound') {
            this.setState({
                'choice': choice
            });
            this.props.socket.emit('setChoice', choice);
        }
    }

    getClasses(artist) {
        if (!artist) {
            artist = this.state.choiceInput && this.state.choiceInput.toLowerCase().replace(/ /g, '_');
        }
        
        var choice = null;
        if (this.state.choiceInput) {
            choice = this.state.choiceInput.toLowerCase().replace(/ /g, '_');
        } else {
            choice = this.state.choice && this.state.choice.toLowerCase().replace(/ /g, '_');
        }

        var classes = "";
        if (this.state.choice === artist) {
            classes += " answer-chosen";
        }

        if (this.state.answer !== null) { // If answer
            if (choice === null) { // If no choice, put all red
                if (this.state.answer !== artist) {
                    classes += ' answer-wrong';
                }
            } else { // Otherwise, wrong one in red
                if (artist === this.state.answer || artist === choice) {
                    classes += " answer-wrong";
                }
            }
            // Correct one always in green
            if (this.state.answer === artist) {
                classes += " answer-correct";
            }
        }
        return classes;
    }

    openPopup() {
        this.props.socket.emit('getSettings');
        this.setState({
            'popup': true
        });
    }

    render() {
        if (!this.props.hasAccount) {
            return (
                <div></div>
            );
        }  else {
            return (
                <div id="game">
                    <div id="header">
                        <div id="header-left">
                            <IconButton aria-label="Rooms" color="inherit" onClick={() => this.props.history.push('/')}>
                                <ArrowBack />
                            </IconButton>
                        </div>
                        <div id="header-title">
                            <h1>{this.state.name}</h1>
                        </div>
                        <div id="header-controls">
                            <IconButton aria-label="Settings" color="inherit" onClick={this.openPopup.bind(this)}>
                                <Settings />
                            </IconButton>
                        </div>
                    </div>
                    <div id="game-container">
                        <video id="game-video" autoPlay loop controls muted src={this.state.video}></video>
                        <div id="answers" className={(this.state.answersDisabled ? 'disabled' : '')+(this.state.state === 'endRound' ? ' final' : '')}>
                            {
                                (this.state.artists.length === 1 && this.state.artists[0] === "free_choice" &&
                                    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                        <input className={'answer answer-chosen'+this.getClasses()} type="text" placeholder="Artist Unknown" value={this.state.choiceInput} onChange={this.setChoiceInput.bind(this)} onKeyDown={(e) => e.key === "Enter" && this.sendChoiceInput()} disabled={this.state.state !== 'inRound' || this.state.sentChoice} maxLength="60" autoFocus />
                                        <span className="answer-text" style={{display: this.state.state === 'endRound' ? 'block' : 'none'}}>{this.state.answer}</span>
                                    </div>
                                ) || this.state.artists.map((answer, i) => {
                                    return (
                                        <div key={i} className={'answer'+this.getClasses(answer)} onClick={() => this.setChoice(answer)}>{formatArtist(answer)}</div>
                                    );
                                })
                            }
                        </div>
                        <div id="game-button-container" style={(this.state.state === 'idle' && this.props.isMod ) ? { display: 'block' } : { display: 'none' }}>
                            <div id="game-button" className={this.state.button === 'LOADING...' ? 'pressed' : ''} onClick={this.startGame.bind(this)}>{this.state.button}</div>
                        </div>
                        <div id="game-percentage" style={this.state.state === 'idle' ? { display: 'none' } : { display: 'block' }}>
                            <div id="game-percentage-bar" style={{ width: `${this.state.bar}%` }}></div>
                        </div>
                    </div>

                    <AlertMessage message={this.state.error} shown={this.state.errorShown} onClose={() => this.props.history.push('/')} />
                    
                    <div id="game-popup" style={this.state.popup ? {display: 'block'} : {display: 'none'}}>
                        <div id="game-popup-overlay" onClick={() => this.setState({'popup': false})}></div>
                        <div id="game-popup-box">
                            <div id="game-popup-header">
                                <h2>Settings</h2>
                                <div id="game-popup-close" onClick={() => this.setState({'popup': false})}>
                                    <IconButton aria-label="Close" color="inherit">
                                        <Close />
                                    </IconButton>
                                </div>
                            </div>
                            <div id="game-popup-settings">
                                <div className="game-popup-setting">
                                    <h3>Game tags</h3>
                                    <textarea id="game-tags" className="st-textarea" placeholder="Tags" rows="4" value={this.state.settings_tags.join(',')} onChange={this.changeTags.bind(this)} disabled={!this.props.isMod}></textarea>

                                    <div>
                                        { Object.keys(this.state.settings_pools).map((pool, i) => {
                                            return (
                                                <button className="st-button game-popup-pool-button" key={i} onClick={() => this.changePool(pool)} disabled={!this.props.isMod}>{pool}</button>
                                            );
                                        }) }
                                    </div>
                                </div>
                                <div className="game-popup-setting">
                                    <h3>Score to win</h3>
                                    <select id="game-score" className="st-select" value={this.state.settings_score} onChange={this.changeScore.bind(this)} disabled={!this.props.isMod}>
                                        <option value="50">50</option>
                                        <option value="70">70</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                                <div className="game-popup-setting">
                                    <h3>Allow presumed</h3>
                                    <div>
                                        <RadioCheck value1="No" value2="Yes" initialData={this.state.settings_presumed ? "Yes" : "No"} onChange={this.changePresumed.bind(this)} />
                                    </div>
                                </div>
                                <div className="game-popup-setting">
                                    <h3>Free choice</h3>
                                    <div>
                                        <RadioCheck value1="No" value2="Yes" initialData={this.state.settings_free_choice ? "Yes" : "No"} onChange={this.changeFreeChoice.bind(this)} />
                                    </div>
                                </div>
                                <div id="game-popup-footer">
                                    <button className="st-button" onClick={this.saveSettings.bind(this)}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(Game);

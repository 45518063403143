
import { Component } from 'react';
import { withRouter } from "react-router-dom";

import Home from './Home';
import Footer from './Footer';

import '../css/Header.css';

class AccountService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        var token = document.location.hash.substr(1);
        document.location.hash = "";

        if (this.props.hasAccount) {
            this.setState({
                loading: false,
            });
            return;
        }

        if (token) {
            localStorage.setItem('token', token);
        } else {
            token = localStorage.getItem('token');
        }

        this.props.socket.on('connected', (data) => {
            // Token auth
            console.log('connected');
            fetch(`${this.props.domain}/auth/getToken?token=${token}`, {credentials: 'include'})
                .then(res => res.json())
                .then(data => {
                    if (data.token) { // Valid token
                        localStorage.setItem('token', data.token);
                        this.props.socket.emit('token', data.token);
                    } else { // Has to login
                        this.setState({
                            loading: false
                        });
                        this.props.setAccount({
                            hasAccount: false,
                        });
                    }
                });
            // Get own player ID
            this.props.setPlayerId(data.id);
        });

        this.props.socket.on('account', (data) => {
            this.setState({
                loading: false,
            });
            this.props.setAccount({
                account: data,
                hasAccount: true,
            });
        });
    }

    componentWillUnmount() {
        this.props.socket.off('account');
        this.props.socket.off('connected');
    }
    
    render() {
        if (this.state.loading) {
            return (
                <div>
                    <div id="rooms">
                        <div id="header">
                            <div id="header-title">
                                <h1>Sakutrain</h1>
                            </div>
                        </div>
                        <div id="home">
                            <div id="home-loading"></div>
                            Loading...
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            );
        } else if (this.props.hasAccount) {
            return this.props.children;
        } else {
            return <Home socket={this.props.socket} domain={this.props.domain} loading={this.state.loading}></Home>;
        }
    }
}

export default withRouter(AccountService);
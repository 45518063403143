
import React, { Component } from 'react';

import '../css/PlayersDropdown.css';

class PlayersDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'shown': false,
        };

        this.ul = React.createRef();
    }

    addMod() {
        this.props.socket.emit('addMod', this.props.player.account.id);
        this.hideDropdown(0);
    }
    removeMod() {
        this.props.socket.emit('removeMod', this.props.player.account.id);
        this.hideDropdown(0);
    }
    kick() {
        this.props.socket.emit('kick', this.props.player.account.id);
        this.hideDropdown(0);
    }
    ban() {
        this.props.socket.emit('ban', this.props.player.account.id);
        this.hideDropdown(0);
    }
    transferOwner() {
        this.props.socket.emit('transferOwner', this.props.player.account.id);
        this.hideDropdown(0);
    }

    toggleDropdown() {
        this.setState({'shown': !this.state.shown});
        setTimeout(() => {
            if (this.state.shown) {
                this.ul.current.focus();
            }
        });
    }
    hideDropdown(t) {
        setTimeout(() => {
            this.setState({'shown': false});
        }, t);
    }

    render() {
        return (
            <div onBlur={this.hideDropdown.bind(this, 150)}>
                <div onClick={this.toggleDropdown.bind(this)}>
                    {this.props.children}
                </div>
                
                <ul tabIndex={0} ref={this.ul} className="players-dropdown" style={{display: this.state.shown ? '' : 'none'}}>
                    <div className="players-dropdown-others" style={{display: this.props.isMe ? '' : 'none'}}>
                        <p style={{margin: "10px"}}>That's you!</p>
                    </div>
                    <div className="players-dropdown-moderation" style={{display: (!this.props.isMod && !this.props.isMe) ? '' : 'none'}}>
                        <p style={{margin: "10px"}}>No action available</p>
                    </div>
                    <div className="players-dropdown-others" style={{display: this.props.isMe ? 'none' : ''}}>
                        <div className="players-dropdown-moderation" style={{display: ((!this.props.player.isMod && this.props.isMod) || (this.props.isOwner && !this.props.player.isOwner)) ? '' : 'none'}}>
                            <li onClick={this.addMod.bind(this)} style={{display: this.props.player.isMod ? 'none' : ''}}>Add Mod</li>
                            <li onClick={this.removeMod.bind(this)} style={{display: this.props.player.isMod ? '' : 'none'}}>Remove Mod</li>
                        </div>
                        <div className="players-dropdown-owner" style={{display: (this.props.isOwner && !this.props.player.isOwner) ? '' : 'none'}}>
                            <li onClick={this.transferOwner.bind(this)}>Transfer Owner</li>
                        </div>
                        <div className="players-dropdown-moderation" style={{display: (this.props.isMod && !this.props.player.isOwner) ? '' : 'none'}}>
                            <li onClick={this.kick.bind(this)} className="danger">Kick</li>
                        </div>
                    </div>
                </ul>
            </div>
        );
    }
}

export default PlayersDropdown;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../css/Home.css';
import '../css/Header.css';

class Home extends Component {
    render() {
        return (
            <div id="rooms">
                <div id="header">
                    <div id="header-title">
                        <h1>Sakutrain</h1>
                    </div>
                </div>
                <div id="home">
                    <div id="home-log-in">
                        <h2 className="separator">Please log in to continue</h2>
                        <div className="home-social">
                            <a href={`${this.props.domain}/oauth/twitter`} className="st-button social twitter">
                                <i className="icon twitter"></i>
                                <span>Log in with Twitter</span>
                            </a>
                            <a href={`${this.props.domain}/oauth/discord`} className="st-button social discord">
                                <i className="icon discord"></i>
                                <span>Log in with Discord</span>
                            </a>
                            {/* <div className="nickname">
                                <a href="/">Continue with a nickname (not recommended)</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);

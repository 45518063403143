import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import RadioCheck from './RadioCheck';

import '../css/Rooms.css';
import '../css/Header.css';

class Rooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'rooms': {},
            'roomName': '',
            'private': false
        };
    }

    componentDidMount() {
        document.title = 'Sakutrain - Rooms';
        this.props.socket.emit('rooms');

        this.props.socket.on('roomCreated', (roomId) => {
            this.props.history.push(`/room/${roomId}`);
        });
    }
    componentWillUnmount() {
        this.props.socket.off('roomCreated');
    }

    changeRoom(e) {
        this.setState({
            roomName: e.target.value
        });
    }
    setPrivate(value) {
        this.setState({
            private: value
        });
    }
    createRoom(e) {
        e.preventDefault();
        this.props.socket.emit('createRoom', {
            roomName: this.state.roomName,
            private: this.state.private,
        });
    }

    render() {
        if (this.props.hasAccount) {
            return (
                <div id="rooms">
                    <div id="header">
                        <div id="header-title">
                            <h1>Sakutrain - Rooms</h1>
                        </div>
                    </div>
                    <div className="rooms-list">
                        <h2>Rooms</h2>
                        <ul className="list-rooms">
                            {Object.keys(this.props.rooms).map((i) => {
                                return (
                                    <li className="list-group-item" key={this.props.rooms[i].name}>
                                        <Link to={`/room/${this.props.rooms[i].id}`}>
                                            <span className="name">{this.props.rooms[i].name}</span>
                                            <span className="badge">{this.props.rooms[i].players}</span>
                                        </Link>
                                    </li>
                                )
                            })}
                            {Object.keys(this.props.rooms).length === 0 && (
                                <span style={{color: '#999', fontSize: '14px'}}>No rooms available</span>
                            )}
                        </ul>
                    </div>
                    <div className="rooms-creator-container">
                        <h2>Create room</h2>
                        <form className="rooms-creator" onSubmit={this.createRoom.bind(this)}>
                            <input type="text" className="st-input" placeholder="Room name" id="roomName" maxLength="20" onChange={this.changeRoom.bind(this)} />
                            <RadioCheck value1="🌍 Public" value2="🔒 Private" onChange={this.setPrivate.bind(this)} />
                            <button className="st-button">Create</button>
                        </form>
                    </div>
                    <div className="rooms-footer">
                        <h2>Account</h2>
                        <button className="st-button" onClick={this.props.logOut.bind(this)}>Log Out</button>
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(Rooms);

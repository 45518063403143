
import React, { Component } from 'react';

import '../css/RadioCheck.css';

class RadioCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'value': this.props.initialData ? this.props.initialData : this.props.value1,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialData !== this.props.initialData) {
            this.setState({
                'value': this.props.initialData,
            });
        }
    }

    setValue(value) {
        this.setState({
            'value': value,
        });
        this.props.onChange(value === this.props.value2);
    }

    render() {
        return (
            <div className='radio-check-container'>
                <div className={'radio-check-input'+(this.state.value === this.props.value1 ? ' active' : '')} onClick={this.setValue.bind(this, this.props.value1)}>
                    {this.props.value1}
                </div>
                <div className={'radio-check-input'+(this.state.value === this.props.value2 ? ' active' : '')} onClick={this.setValue.bind(this, this.props.value2)}>
                    {this.props.value2}
                </div>
            </div>
        );
    }
}

export default RadioCheck;

import React, { Component } from 'react';

import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

import '../css/AlertMessage.css';

class AlertMessage extends Component {
    render() {
        return (
            <div id="alert-message" style={{display: this.props.shown ? 'block' : 'none'}}>
                <div id="alert-message-overlay" onClick={this.props.onClose.bind(this)}></div>
                <div id="alert-message-box">
                    <div id="alert-message-header">
                        <h2>Error</h2>
                        <div id="alert-message-close" onClick={this.props.onClose.bind(this)}>
                            <IconButton aria-label="Close" color="inherit">
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                    <div id="alert-message-settings">
                        <div className="alert-message-setting">
                            <p>{this.props.message}</p>
                        </div>
                        <div id="alert-message-footer">
                            <button className="st-button" onClick={this.props.onClose.bind(this)}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlertMessage;
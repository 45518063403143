import React, { Component } from 'react';

import '../css/PostList.css';

class PostList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            posts: this.props.posts,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.posts !== prevProps.posts) {
            this.setState({ posts: this.props.posts });
        }
    }
    
    render() {
        return (
            <div id="post-list">
                <ul id="post-list-posts">
                    {this.state.posts.map((post, i) => {
                        return (
                            <li key={i}>
                                <a href={`https://www.sakugabooru.com/post/show/${post.id}`} target="_blank" rel="noreferrer">#{post.id}</a> by <a href={`https://www.sakugabooru.com/post?tags=${post.artist}`} target="_blank" rel="noreferrer">{post.artist}</a> (drawn with <a href={`https://www.sakugabooru.com/post?tags=${post.series}`} target="_blank" rel="noreferrer">{post.series}</a>)
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default PostList;

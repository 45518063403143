import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';

import '../css/Chat.css';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            chatMessages: [
                {color: '#a8ff95', author: null, message: 'Welcome!'}
            ],
        };
    }
    
    componentDidMount() {
        this.props.socket.on('message', (message) => {
            this.setState({chatMessages: this.state.chatMessages.concat(message) });
            animateScroll.scrollToBottom({
                duration: 100,
                containerId: "chat-messages"
            });
        });
        this.props.socket.on('error', (error) => {
            this.componentWillUnmount();
        });
    }
    componentWillUnmount() {
        this.props.socket.off('message');
    }

    updateMessage(event) {
        this.setState({ message: event.target.value });
    }
    submitMessage(event) {
        event.preventDefault();
        if (this.state.message.length > 0) {
            this.props.socket.emit('chatter', this.state.message);
            this.setState({ message: '' });
        }
    }

    showMessageContent(message) {
        let msg;
        if (message.author) {
            if (message.role) {
                let color;
                switch (message.role.toLowerCase()) {
                    case 'admin':
                        color = '#ff9595';
                        break;
                    case 'donator':
                        color = '#ffff95';
                        break;
                    default:
                        color = '#ffffff';
                        break;
                }
                msg = <span>
                    <span style={{ color: color }}>{message.author}</span>: <span style={{ color: '#fafafa' }}>{message.message}</span>
                </span>;
            } else {
                msg = <span>{message.author}: <span style={{ color: '#fafafa' }}>{message.message}</span></span>;
            }
        } else {
            msg = <span>{message.message}</span>
        }
        return msg;
    }

    render() {
        return (
            <div id="chat">
                <ul id="chat-messages">
                    {this.state.chatMessages.map((message, i) => {
                        return (
                            <li key={i} style={{color: message.color}}>
                                { this.showMessageContent(message) }
                            </li>
                        );
                    })}
                </ul>
                <form id="chat-input" onSubmit={this.submitMessage.bind(this)}>
                    <input type="text" onChange={this.updateMessage.bind(this)} value={this.state.message} maxLength="200" />
                </form>
            </div>
        );
    }
}

export default Chat;

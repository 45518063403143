import React, { Component } from 'react';
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import io from 'socket.io-client';

import Game from './components/Game';
import Players from './components/Players';
import Chat from './components/Chat';
import PostList from './components/PostList';
import Rooms from './components/Rooms';
import AccountService from './components/AccountService';
import AlertMessage from './components/AlertMessage';
import Footer from './components/Footer';
import ToS from './components/ToS';

import './css/App.css';

var domain;
if (window.location.hostname === "sakutrain.yatangakissou.ovh" || window.location.hostname === "sakutra.in") {
    domain = 'https://api.sakutrain.yatangakissou.ovh';
} else {
    domain = 'http://localhost:8088';
}
const socket = io(domain, {
    query: {
        token: localStorage.getItem('token')
    }
});
console.log(socket);

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rooms: {},
            account: {},
            loading: true,
            isOwner: false,
            isMod: false,
            playerId: null,
            hasAccount: null,
            posts: [],
            
            error: null,
            errorShown: false,
        };
    }

    setAccount(data) {
        this.setState({
            account: data.account,
            hasAccount: data.hasAccount,
        });
    }
    
    setPlayerId(id) {
        this.setState({
            playerId: id,
        });
    }

    componentDidMount() {
        socket.on('rooms', (rooms) => {
            this.setState({
                rooms: rooms
            })
        });

        socket.on('loggedOut', () => {
            this.setState({
                hasAccount: false,
                loading: true,
            });
        });

        socket.on('disconnect', () => {
            this.setState({
                error: 'Server unreachable',
                errorShown: true
            });
        });
    }

    componentWillUnmount() {
        socket.off('rooms');
        socket.off('loggedOut');
        socket.off('disconnect');
    }
    
    logOut() {
        socket.emit('logOut');
    }
    
    addPost(post) {
        this.setState({
            posts: this.state.posts.concat(post)
        });
    }

    setRights(data) {
        this.setState(data);
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/tos">
                        <ToS></ToS>
                        <Footer></Footer>
                    </Route>
                    <AccountService socket={socket} domain={domain} hasAccount={this.state.hasAccount} setAccount={this.setAccount.bind(this)} setPlayerId={this.setPlayerId.bind(this)}>
                        <Switch>
                            <Route path={`/room/:id`}>
                                <div id="app-game">
                                    <Game id="contains-game" socket={socket} domain={domain} hasAccount={this.state.hasAccount} isOwner={this.state.isOwner} isMod={this.state.isMod} addPost={this.addPost.bind(this)}></Game>
                                    <Players id="contains-players" socket={socket} isOwner={this.state.isOwner} isMod={this.state.isMod} playerId={this.state.playerId} setRights={this.setRights.bind(this)}></Players>
                                    <div id="contains-right">
                                        <PostList id="contains-chat" socket={socket} posts={this.state.posts}></PostList>
                                        <Chat id="contains-chat" socket={socket}></Chat>
                                    </div>
                                </div>
                            </Route>
                            <Route path="/">
                                <Rooms socket={socket} rooms={this.state.rooms} hasAccount={this.state.hasAccount} logOut={this.logOut.bind(this)}></Rooms>
                                <Footer></Footer>
                            </Route>
                        </Switch>
                    </AccountService>
                </Switch>

                <AlertMessage message={this.state.error} shown={this.state.errorShown} onClose={() => window.location.href = '/'} />
            </div>
        );
    }
}

export default withRouter(App);

import React, { Component } from 'react';
import { withRouter } from "react-router";

import { IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

import '../css/ToS.css';

class ToS extends Component {
    componentDidMount() {
        document.title = "Sakutrain - Terms of Service";
    }
    
    render() {
        return (
            <div id="tos">
                <div id="header">
                    <div id="header-left">
                        <IconButton aria-label="Rooms" color="inherit" onClick={() => this.props.history.goBack()}>
                            <ArrowBack />
                        </IconButton>
                    </div>
                    <div id="header-title">
                        <h1>Sakutrain</h1>
                    </div>
                </div>
                <div id="tos-content">
                    <h2>Terms of Service</h2>
                    <p>
                        sakutra.in ("Sakutrain") is an educational game platform that allows you to test your knowledge of animators and their works and compete with other players.
                        By accessing the website, you agree to the following terms of service. If you do not agree to these terms, you may not access or use the website.
                        Please notice that these terms of service are subject to change at any time without prior notice.
                    </p>
                    <h2>Website Content</h2>
                    <p>
                        The website is using the API of <a href="https://twitter.com/" target="_blank" rel="noreferrer">Twitter</a>, <a href="https://discord.com" target="_blank" rel="noreferrer">Discord</a> to log in with your account on the website. The data of the users (usernames, profile pictures) are stored on the website and are not shared with any third party.
                        The information, videos provided while playing the game are provided randomly by <a href="https://sakugabooru.com" target="_blank" rel="noreferrer">sakugabooru.com</a>. Sakutrain is not responsible for the content of the videos.
                    </p>
                    <h2>Privacy policy</h2>
                    <p>
                        Sakutrain does not use any cookies or other technologies to collect personal information. By logging in with Twitter or Discord, you agree to share your username and profile picture with Sakutrain. These information are not shared with any third party and allow Sakutrain to create a social network of players, and allow you to recognize your friends.
                        If you want these information to be deleted from our servers, please contact us the <a href="mailto:ozachimc@gmail.com">webmaster</a>.
                    </p>
                    <h2>Copyright Infringment</h2>
                    <p>
                        If you believe any content shown on this site infriges upon your copyright, please contact us the <a href="mailto:ozachimc@gmail.com">webmaster</a> with the following information:
                    </p>
                    <ul>
                        <li>The content that infringes upon your copyright</li>
                        <li>A proof that you own the copyright</li>
                        <li>Your contact details</li>
                    </ul>
                    <p>
                        Please be aware that Sakutrain does not store any video or image on its server, and therefore does not have any control over this content. It is only possible for us to stop the content from being displayed on the website.
                    </p>
                </div>
            </div>
        );
    }
}

export default withRouter(ToS);

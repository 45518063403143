
import React, { Component } from 'react';
import { formatArtist } from '../services/Artist';

import Check from '@mui/icons-material/Check';

import '../css/Players.css';
import PlayersDropdown from './PlayersDropdown';

class Players extends Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            winner: null,
        };
    }
    
    componentDidMount() {
        this.props.socket.on('playersChange', (players) => {
            console.log('playersChange', players);
            this.setState({ 
                players: players
            });

            var me = players.find(player => player.id === this.props.playerId);
            if (me) {
                this.props.setRights({
                    'isOwner': me.isOwner,
                    'isMod': me.isMod,
                });
            }
        });
        this.props.socket.on('randomPost', (data) => {
            var players = Array.from(this.state.players);
            for (var i = 0; i < players.length; i++) {
                players[i].choice = null;
            }
            this.setState({
                players: players,
                winner: null,
            });
        });
        this.props.socket.on('endGame', (data) => {
            this.setState({
                winner: data.winner,
            });
        });
        this.props.socket.on('error', (error) => {
            this.componentWillUnmount();
        });
    }
    componentWillUnmount() {
        this.props.socket.off('playersChange');
        this.props.socket.off('randomPost');
        this.props.socket.off('endGame');
    }

    render() {
        return (
            <div id="players">
                <ul id="list-players">
                    {this.state.players.map((player) => {
                        return (
                            <PlayersDropdown key={player.id} socket={this.props.socket} player={player} isMe={player.id === this.props.playerId} isMod={this.props.isMod} isOwner={this.props.isOwner}>
                                <li className={'player'+(player.account.id === this.state.winner ? ' player-won' : '')}>
                                    <img className="image" src={player.account.image} alt={player.account.name} />
                                    <span className="text">
                                        <span className="name" title={player.account.fullname}>{player.isOwner ? '👑 ' : player.isMod ? '🔵 ' : ''}{player.account.name}</span>
                                        <span className={'artist'+(player.isRight ? ' artist-right' : ' artist-wrong')} style={{display: player.choice ? 'block' : 'none'}}>{formatArtist(player.choice)}</span>
                                        <span className="artist" style={{display: (player.choice === null && player.hasChoice) ? 'block' : 'none'}}><Check /></span>
                                    </span>
                                    <span className="score">{player.score??0}</span>
                                </li>
                            </PlayersDropdown>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default Players;
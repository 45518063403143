import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/Footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            posts: this.props.posts,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.posts !== prevProps.posts) {
            this.setState({ posts: this.props.posts });
        }
    }
    
    render() {
        return (
            <footer>
                <ul>
                    <li><Link to="/tos">Terms of Service</Link></li>
                    <li><a href="https://www.sakugabooru.com/" target="_blank" rel="noreferrer">sakugabooru</a></li>
                    <li><a href="https://chrome.google.com/webstore/detail/sakuga-extended/khmnmdaghmhkcbooicndamlhkcmpklmc" target="_blank" rel="noreferrer">Sakuga Extended</a></li>
                </ul>
            </footer>
        );
    }
}

export default Footer;
